<template>
  <div class="complaint">
    <!-- 头部 -->
      <van-nav-bar fixed placeholder z-index="100" title="举报" left-arrow  @click-left="onClickLeft" />
      <div class="line"></div>
        <van-radio-group v-model="radio">
          
           <van-radio name="7" v-if="this.type == 6">
            晒单图片与商品不符
            <template #icon="props">
              <img class="img-icon" :src="props.checked ? activeIcon :  inactiveIcon" />
            </template>
          </van-radio>
          
          <van-radio name="1"  v-else>
            内容不恰当
            <template #icon="props">
              <img class="img-icon" :src="props.checked ? activeIcon :  inactiveIcon" />
            </template>
          </van-radio>

          <van-radio name="2">
            敏感信息
            <template #icon="props">
              <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon" />
            </template>
          </van-radio>
          <van-radio name="4">
            政治类
            <template #icon="props">
              <img class="img-icon" :src="props.checked ?  activeIcon :  inactiveIcon" />
            </template>
          </van-radio>
          <van-radio name="5">
            虚假信息
            <template #icon="props">
              <img class="img-icon" :src="props.checked ?   activeIcon :  inactiveIcon" />
            </template>
          </van-radio>
          <van-radio name="3">
            广告灌水
            <template #icon="props">
              <img class="img-icon" :src="props.checked ?   activeIcon :  inactiveIcon" />
            </template>
          </van-radio>
          <van-radio name="6">
            其他
            <template #icon="props">
              <img class="img-icon" :src="props.checked ?  activeIcon :  inactiveIcon" />
            </template>
            </van-radio>
        </van-radio-group>
      <div class="text">
          <textarea maxlength="200" @input="descInput" placeholder="说明详细原因,帮助我们更快的处理举报内容(选填)" v-model="value"></textarea>
          <span>{{remnant}}/200</span>
      </div>
       
      <div class="btn">
        <button @click="onSubmit">提交</button>
      </div>
  </div>
</template>

<script>
import { addUserReport } from "../api/community";
import qs from "qs"

  export default {
     data(){
       return{
          radio:'1',
          activeIcon: 'https://i.loli.net/2021/09/17/Pk3KUz6pdSDyn1q.png',
          inactiveIcon: 'https://i.loli.net/2021/09/17/v8nziDCqKP49S1N.png',
          value:'',
          remnant: '0',
          id:'',
          type:'',
          pageType:'',
          tabType:'',
       }
     },
     methods:{
        //  返回
        onClickLeft() {
          this.$router.go(-1)
        },
        //显示输入文字长度
        descInput(){
          this.remnant = this.value.length
        },
        //举报
        async onSubmit(){
          if(this.type == 1){
              const data = {
                reportContent:this.value,
                reportReasons:this.radio,
                reportType:2,
                targetId:this.id
              }
               const res = await addUserReport(data)
                if(res.code === 0 ){
                   if(this.pageType == 1){
                     this.$toast("举报成功")
                     this.$router.go(-1)
                   }else{
                     this.$toast("举报成功")
                     this.$router.push({
                       name:'Communityadmin',
                       query:{
                         tabType:this.tabType
                       }
                     })
                   }
                }else{
                  this.$toast(res.msg)
                }
          }else if(this.type == 2){
               const data = {
                reportContent:this.value,
                reportReasons:this.radio,
                reportType:1,
                targetId:this.id
              }
               const res = await addUserReport(data)
                if(res.code === 0 ){
                   if(this.pageType == 1){
                     this.$toast("举报成功")
                     this.$router.go(-1)
                   }else{
                     this.$toast("举报成功")
                     this.$router.push({
                       name:'Communityadmin',
                        query:{
                          tabType:this.tabType
                        }
                     })
                   }
                }else{
                  this.$toast(res.msg)
                }

          }else if(this.type == 3 ){
               const data = {
                reportContent:this.value,
                reportReasons:this.radio,
                reportType:3,
                targetId:this.id
              }
                const res = await addUserReport(data)
                if(res.code === 0 ){
                    if(this.pageType == 1){
                     this.$toast("举报成功")
                     this.$router.go(-1)
                   }else{
                     this.$toast("举报成功")
                     this.$router.push({
                       name:'Communityadmin',
                       query:{
                         tabType:this.tabType
                       }
                     })
                   }
                }else{
                  this.$toast(res.msg)
                }
          }else if(this.type == 4 ){
               const data = {
                reportContent:this.value,
                reportReasons:this.radio,
                reportType:4,
                targetId:this.id
              }
              const res = await addUserReport(data)
              if(res.code === 0 ){
                  if(this.pageType == 1){
                     this.$toast("举报成功")
                     this.$router.go(-1)
                   }else{
                     this.$toast("举报成功")
                     this.$router.push({
                       name:'Communityadmin',
                        query:{
                          tabType:this.tabType
                        }
                     })
                   }
              }else{
                this.$toast(res.msg)
              }
          }else if(this.type == 5 ){
              const data = {
                reportContent:this.value,
                reportReasons:this.radio,
                reportType:5,
                targetId:this.id
              }
               const res = await addUserReport(data)
                if(res.code === 0 ){
                  this.$toast("举报成功")
                  this.$router.go(-1)
                }else{
                  this.$toast(res.msg)
                }

          }else{
              const data = {
                reportContent:this.value,
                reportReasons:this.radio,
                reportType:6,
                targetId:this.id
              }
              const res = await addUserReport(data)
              if(res.code === 0 ){
                this.$toast("举报成功")
                this.$router.go(-1)
              }else{
                this.$toast(res.msg)
              }
          }
        }
     },
     created(){
        this.id = this.$route.query.id
        this.type  = this.$route.query.type
        this.pageType = this.$route.query.pageType
        this.tabType = this.$route.query.tabType
        if(this.type == 6 ){
          this.radio = "7"
        }
     }
  }
</script>

<style lang="scss" scoped>
.complaint{
    font-family: PingFang SC;
    ::v-deep .van-nav-bar__text{
      color: #333333;
      font-size: 15px;
      font-weight: 500;
    }
    ::v-deep .van-nav-bar .van-icon{
      color: #333333;
    }
    ::v-deep .van-nav-bar__title{
      font-size: 18px;
      font-weight: bold;
      color: #333333;
    }
    ::v-deep .van-nav-bar__content{
      height: 57px;
    }
     ::v-deep .topfix{
      position:fixed;
      z-index:100;
      width:100%;
      top:0;
    }
    .line{
      width: 100%;
      height: 11px;
      background: #F4F4F4;
    }
    ::v-deep .van-radio-group{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-left: 20px;
      margin-right: 20px;
      .van-radio{
        width: 50%;
        margin-top: 25px;
      }
    }
    ::v-deep .van-radio__label{
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
    }
    ::v-deep .van-radio__icon{
      width: 15px;
      height: 15px;
    }
    ::v-deep .van-radio__icon--round{
       width: 15px;
       height: 15px;
    }
    .img-icon{
       width: 15px;
       height: 15px;
    }
    .text{
      position: relative;
      width: 335px;
      height: 145px;
      margin-top: 25px;
      margin-left: 20px;
      border-radius: 10px;
      textarea{
        width: 335px;
        height: 145px;
        background: #F8F8F8;
        border-radius: 10px;
        font-size: 12px;
        padding-left: 15px;
        padding-top: 15px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #999999;
      }
      span{
        position: absolute;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #999999;
        right: 15px;
        bottom: 15px;
      }
    }
    .btn{
      margin-top: 40px;
      margin-left: 32px;
      width: 302px;
      height: 57px;
      box-shadow: 0px 8px 8px 0px rgba(69, 136, 255, 0.33);
      border-radius: 49px;
      button{
         width: 100%;
         height: 100%;
         border-radius: 49px;
         background: #4588FF;
         font-size: 17px;
         font-family: PingFang SC;
         font-weight: 500;
         color: #FEFEFE;
      }
    }
    
}
</style>